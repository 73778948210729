import React, { useState } from 'react'

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import AKSMenu from "./components/aks_sidemenu"

import ReactPlayer from 'react-player/youtube'


const Videos = () => {

  const [video, newVideo] = useState('https://www.youtube.com/watch?v=HYAIZGguVdo');

  const renderLoadButton = (id, label) => {
    return (
      <button onClick={() => newVideo('https://www.youtube.com/watch?v=' + id)} className={style.videoButton}>
        {/* <img src={'https://i.ytimg.com/vi/' + id + '/maxresdefault.jpg'} /> */}
        {label}
      </button>
    )
  }

  return (

    <Layout>

      <Seo title="Videos" bodyClass='stp' />

      <div className="column column--sidebar alignright">
        <div className="sticky">
        <AKSMenu/>
        </div>
      </div>

      <div className="column column--content">

        <h1 className={style.titleLowercase}>Videos</h1>

        <div className={style.video}>
          <ReactPlayer
            // ref={this.ref}
            className='react-player'
            width='100%'
            height='100%'
            url={video}
            // playing
          />
        </div>
        
        <hr/>

        <p>Browse through the latest videos.</p>
        
          {renderLoadButton('HYAIZGguVdo', 'STP Singles Promo')}<br/>
          {renderLoadButton('JiVKs2nP-jU', 'Specyal T - Live @ Free Times Cafe')}<br/>
          {renderLoadButton('7RM1NS2N47c', 'Chasing Sunday - Later Tonite (Live In Rehearsal)')}<br/>
          {renderLoadButton('-nPOA2ULiGo', 'Chasing Sunday Live @ The Tennessee ')}<br/>
          {renderLoadButton('8Lfpp4zZgpM', 'Specyal T - Side Effects (Official Video) (HG Awareness)')}

        

      </div>


    </Layout>
  )
}
export default Videos