import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import TopBar from "../topbar/topbar"
import Header from "../header/header"
import Content from '../content/content'
import Footer from '../footer/footer'
import Seo from "../../components/seo"


//Global

// import BackgroundImage from 'gatsby-background-image'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query STPQuery {
      site {
        siteMetadata {
          title,
          stp{
            title,
            type,
            menuLinks {
              name
              link
              subMenu {
                link
                name
              }
            },
            instagram,
            facebook,
            twitter,
            youtube,
            soundcloud
          }
        }
      }

      desktop: file(relativePath: {eq: "bg_stp.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: {eq: "logo-stp.png"}) {
        publicURL
      }


    }
  `)

  // const imageData = data.desktop.childImageSharp.fluid


  return (
    <>

      <Seo sectionClass="st"/>

      {/* <BackgroundImage className='pagebackground' fluid={imageData} loading="eager"/> */}
      <TopBar/>
      
      <div className="wrapper">
      
      <Header 
        siteTitle={`Title` || data.site.siteMetadata.stp.title} 
        section={data.site.siteMetadata.stp.type}
        sectionTitle={data.site.siteMetadata.stp.title} 
        sectionURL={`/stp`}
        menuLinks={data.site.siteMetadata.stp.menuLinks}
        logoURL={data.logo.publicURL}  

        />
      
      <Content>{ children }</Content>
      <Footer/>

      </div>
      

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout