import { Link } from "gatsby"
import * as style from '../../../components/content/content.module.scss'
import React from "react"



const AKSMenu = () => (
  <>

    <span className={style.titleLowercase}>Ave Kode Studios</span>
    <p><strong>Home of the Indie M.D. <span role="img" aria-label="Multiple Musical Notes">🎶</span> <br/>Industry Pro In The K.N.O.W. </strong></p>
    
    <hr/>
    
    <p>
      <Link to='/stp/artists'>Artists</Link><br/>
      <Link to='/stp/videos'>Videos</Link>
    </p>

    <hr/>
    
    <p><strong>email</strong><br/>
    <a href="mailto:avenuekode@gmail.com">avenuekode@gmail.com</a></p>

  </>
)

export default AKSMenu